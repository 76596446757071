import React, {useState} from 'react';
import {FormItem, SmartSelect} from "components/_common";
import {in_array, toArray} from "utils/func";
import {rolesModerator, DIVISION_TITLE} from "utils/config";

const UserEdit = props => {

	const {
		edit, formRef, avatarFileRef,
		userId, userData, setUserData,
		divisions, rolesList, user,
		roleNameDefault
	} = props;
	// console.log(user.role);
	// console.log(userData);
	// console.log(userId);
	let roleSelect = null, divisionSelect = [];

	const [selectedRole, setSelectedRole] = useState(
		userData ? userData.role : roleNameDefault
	);
	// console.log(selectedRole);

	let {divId: userDivId = 0, dopDivId = []} = userData ?? {};

	if (edit && in_array(user.role, rolesModerator) && userId !== 1) {

		// roleSelect = (
		// 	<div className="form-item">
		// 		<label>Роль</label>
		// 		<select
		// 			name={"role"}
		// 			defaultValue={userData.role ? userData.role.trim() : "user"}
		// 			onChange={(e) => setSelectedRole(e.target.value)}
		// 		>
		// 			{
		// 				Object.keys(rolesList).map(key =>
		// 					<option
		// 						key={"role-opt-" + key}
		// 						value={key.trim()}
		// 						// defaultValue={key}
		// 					>
		// 						{user.userRoles[key]}
		// 					</option>
		// 				)
		// 			}
		// 		</select>
		// 	</div>
		// );
		let rolesOptions = {};
		for (const rl in rolesList) {
			rolesOptions[rl] = {
				value: rolesList[rl]
			}
		}

		roleSelect = <FormItem
			edit={true}
			name={"role"}
			fieldType={"select"}
			options={rolesOptions}
			label={"Роль"}
			defVal={selectedRole}
			changeAction={(e) => setSelectedRole(e.target.value)}
			isRequire={true}
		/>
	}

	if (edit && in_array(user.role, rolesModerator) && divisions) {

		// let selectDivisions = divisions;
		// selectDivisions["0"] = {id: "0", name: "нет задано"};
		//
		// divisionSelect.push(
		// 	<div className="form-item">
		// 		<label>{DIVISION_TITLE.one}</label>
		// 		<select
		// 			name={"divId"}
		// 			defaultValue={userData ? userData.divId : "0"}
		// 			// onChange={(e) => setSelectedDivId(e.target.value)}
		// 		>
		// 			{
		// 				Object.keys(selectDivisions).map(key =>
		// 					<option
		// 						key={"role-divid-" + key}
		// 						value={key}
		// 					>
		// 						{selectDivisions[key].name}
		// 					</option>
		// 				)
		// 			}
		// 		</select>
		// 	</div>
		// );

		let divisionsOptions = {
			0: {
				value: "без офиса"
			}
		};

		for (const div of Object.values(divisions)) {
			divisionsOptions[div.id] = {
				value: div.name
			}
		}
		// console.log(userDivId);
		divisionSelect.push(<FormItem
			key={'divId'}
			edit={true}
			name={"divId"}
			fieldType={"select"}
			options={divisionsOptions}
			label={"Основной "+DIVISION_TITLE.one}
			defVal={userDivId}
			isRequire={false}
		/>);

		let divOptions = [];

		// formRef.current['divId'] =
		for (let div of Object.values(divisions)) {
			if (userDivId == div.id)
				continue;

			divOptions.push({
				name: div.name,
				value: div.id,
				selected: in_array(div.id, dopDivId),
				isGroup: false
			});
		}

		divisionSelect.push(
			<div className="form-item" key={"dopDivId"}>
				<SmartSelect
					name={"divId"}
					label={"Дополнительные " + DIVISION_TITLE.many}
					options={divOptions}
					multi={true}
					withSearch={false}
					onSelect={(res) => {
						console.log('onSelect', [...res.keys()]);
						// setUserData({});
						setUserData(prev => ({
							...prev,
							dopDivId: [...res.keys()]
						}));
					}}
				/>
			</div>
		);

	}
	else if (userData && userData.divId && divisions && divisions[userData.divId] !== undefined)
		divisionSelect = <FormItem
			name={"divId"}
			edit={false}
			label={DIVISION_TITLE.one}
			defVal={divisions[userData.divId].name}
		/>;

	return (
		<form ref={formRef} encType={"multipart/form-data"}>

			<FormItem
				edit={edit}
				name={"name"}
				label={"Имя пользователя"}
				isRequire={true}
				defVal={userData ? userData.name : ''}
				ico={"user"}
			/>

			{roleSelect}
			{divisionSelect}

			<FormItem
				edit={user.role == 'admin' ? edit : false}
				name={"login"}
				label={"Логин (для входа в систему)"}
				fieldType={"text"}
				isRequire={true}
				defVal={userData ? userData.login : ''}
				ico={"user-o"}
				descr={"Допустимые знаки: a-z0-9_"}
				changeAction={(e, val) => {
					return val.replace(/\W/i, '');
				}}
			/>
			<FormItem
				edit={edit}
				name={"password"}
				label={"Пароль"}
				fieldType={"password"}
				isRequire={!userId}
				defVal={""}
				ico={"key"}
				descr={userId ? "задайте новый пароль для смены" : ''}
			/>


			<FormItem
				edit={edit}
				name={"email"}
				label={"Email"}
				isRequire={false}
				defVal={userData ? userData.email : ''}
				ico={"envelope"}
			/>
			<FormItem
				edit={edit}
				name={"phone"}
				label={"Телефон"}
				defVal={userData ? userData.phone : ''}
				ico={"phone"}
			/>

			<FormItem
				edit={edit}
				name={"car"}
				label={"Автомобиль"}
				defVal={userData ? userData.car : ''}
				hidden={!in_array(selectedRole, ['driver'])}
				ico={"car"}
			/>
			<FormItem
				edit={edit}
				name={"passport"}
				label={"Паспортные данные"}
				defVal={userData ? userData.passport : ''}
				hidden={!in_array(selectedRole, ['installer'])}
				ico={"id-card"}
			/>
			<FormItem
				edit={edit}
				name={"info"}
				label={"Дополнительные данные"}
				defVal={userData ? userData.info : ''}
				ico={"info"}
				fieldType={"txt"}
			/>
			<FormItem
				edit={edit}
				name={"num"}
				label={"Порядок"}
				defVal={userData ? userData.num : ''}
				ico={"sort-numeric-asc"}
				descr={"порядок сортировки, может исп. в разных частях, целое число 0 и больше"}
				numericRound={0}
			/>
			<div className="user-edit-avatar">
				<h3>Изображение профиля</h3>
				<div className="flx">
					<div className="-av">
						{
							(userData && userData.avatar) ? < img src={userData.avatar} alt={userData.name}/> : null
						}
					</div>
					<FormItem
						edit={edit}
						name={"avatar"}
						label={null}
						fieldType={"file"}
						isRequire={false}
						reff={avatarFileRef}
						// defVal={userData && userData.login}
					/>
				</div>

			</div>

		</form>
	)

};

export default UserEdit;
