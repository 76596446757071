import React, {useState, useEffect, useCallback} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Tabs} from "components/_common";
import {setCalendarSelectDateAction, setSearchAction, setCalendarDates} from "actions/GUIActions";
import {MENU_TITLE} from "utils/config";
import {Switch, Route, useParams} from 'react-router-dom';
import Page404 from "components/Page404";
import Bills from "components/tables/Bills";
import EmployeeReportCard from "components/tables/EmployeeReportCard";
// import {TablesBgr} from "components/tables/utils";
import {dateFormat, hashObject, in_array, isModerator, isUser} from "utils/func";
import {ContentHeaderDateType} from "../../components/_common/ContentHeader";
// import cn from "classnames";

const API_URL = 'tables.php';

const TABLE_DATES_INIT = {
	start: new Date(),
	end: new Date()
};

const TablesContainer = props => {

	const {tableName = ''} = useParams();
	const {user, GUI, actions} = props;

	const [disabledAnyActions, setDisabledAnyActions] = useState(false);
	const [data, setData] = useState({});
	const [selectOnlyUserId, setSelectOnlyUserId] = useState(0);
	const [activeTabId, setActiveTabId] = useState(-1);

	const [tableDates, setTableDates] = useState(
		TABLE_DATES_INIT
		// GUI.calendarDates
	);
	// console.log(tableName);

	// const [selectedColor, setSelectedColor] = useState('');
	const [selectedTableCells, setSelectedTableCells] = useState({});
	// const [colorsPanelFixed, setColorsPanelFixed] = useState(false);

	let billsTable = null;
	let billsTableFixedAfter = 0;

	let apiActionProcess = false;
	const getData = async (isSubscribe = true, calDates = null, search = '', userId = null, tabId = null) => {
		if (!isSubscribe || disabledAnyActions)
			return;
		// console.clear();
		setDisabledAnyActions(true);
		console.log('%c------------getData-------------', 'background: #000;color: #fff');

		let dates = calDates ? calDates : tableDates;

		// console.log(`> tableDatesStart:  ${dateFormat(tableDates.start)}`);
		// console.log('> calDates:', calDates ? dateFormat(calDates.start) : 'нет');
		console.log(`>> date Select: ${dateFormat(dates.start)} tableName=${tableName} `);
		// console.dir(dates);

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'get',
				userId: user.id,
				selectOnlyUserId: userId !== null ? userId : selectOnlyUserId,
				tableName: tableName,
				tabId: tabId !== null ? tabId : activeTabId,
				dates: dates,
				search: search
			});

			apiAction(res, (response) => {

				// console.dir(response);
				// console.log(activeTabId);
				if (activeTabId == -1 && response.tabs.length) {
					setActiveTabId(response.tabs[0]['id']);
				}

				setData({
					...response
				});
				if (calDates)
					setTableDates(calDates);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
				setDisabledAnyActions(false);
			});
			apiActionProcess = false;

		}
	};

	const doSearch = str => {
		getData(true, null, str).then(null);
	};
	const setSelectOnlyUserIdAction = (isChecked) => {
		const userId = isChecked ? user.id : 0;
		setSelectOnlyUserId(userId);
		getData(true, null, '', userId).then(null);
	}
	const changeTab = (tabId) => {
		setActiveTabId(tabId)
		getData(true, null, '', null, tabId).then(null);
	}

	const onCalendarSelectDate = (dates) => {
		// console.dir('onCalendarSelectDate', dates);
		// console.dir(GUI.calendarDates);
		getData(true, dates).then(null);
	};

	const billsOnSelectMonth = async (dates) => {
		console.log(`Bills - onSelectMonth`, GUI.calendarDates);
		// console.log(dates);

		//не меняем ДАТЫ в календаре
		// actions.setCalendarDates(dates);

		await getData(true, dates);

	}

	const onScroll = (e) => {

		billsTable = document.getElementById('bills-table');

		if (billsTable && billsTableFixedAfter === 0) {
			let rect = billsTable.getBoundingClientRect();
			billsTableFixedAfter = rect.top;
		}

		if (e.target.documentElement.scrollTop >= billsTableFixedAfter) {

			if (billsTable && !billsTable.classList.contains('fixed')) {
				billsTable.classList.add('fixed');
			}
		}
		else {
			if (billsTable && billsTable.classList.contains('fixed')) {
				billsTable.classList.remove('fixed');
			}
		}
	};

	const doTablesApiAction = async (options, callback, finalCallback, refreshData = true) => {
		if (!apiActionProcess && !disabledAnyActions) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				...options,
				userId: user.id,
				tableName: tableName,
			});

			apiAction(res, async (response) => {
				console.log(`doTablesApiAction ${options.action}`);
				if (typeof callback == 'function')
					callback(response, setData);

				// await getData(true);
				if (GUI.socket) {
					GUI.socket.emit('tablesChanged', {
						userId: user.id,
						tableName: tableName,
					});
				}

			}, async () => {
				apiActionProcess = false;
				actions.hideLoading();

				if (refreshData)
					await getData(true);

				if (typeof finalCallback == 'function')
					finalCallback(res, setData, getData);

			});
			apiActionProcess = false;
		}
	}

	useEffect(() => {
			let isSubscribe = true;
			console.log(`TablesContainer useEffect`);

			getData(isSubscribe, null).then(null);
			actions.setCalendarSelectDateAction(onCalendarSelectDate);
			actions.setSearchAction(doSearch);
			window.addEventListener("scroll", onScroll);

			if (GUI.socket) {
				GUI.socket.on('tablesRefresh', res => {
					console.log('socket say %ctablesRefresh', 'color: #fff;background: red;');
					//обновляем таблицы при изменении

					if (res.tableName == tableName && res.userId != user.id) {
						console.log('>> DO refresh');
						getData(isSubscribe, null).then(null);
					}
					else
						console.log('>> NO do refresh');
				});
			}
			else
				console.log('no socket');
			return () => {
				isSubscribe = false;
				//отключаем функцию обработчик на календаре
				actions.setCalendarSelectDateAction(null);
				// actions.setSearchAction(null);
				window.removeEventListener("scroll", onScroll);
			}
		},
		[]
	);
	useEffect(() => {
		getData(true, null).then(null);
	}, [tableName]);

	const save = async (id, tabId = 0, field, value) => {
		// console.clear();

		console.log('save', disabledAnyActions, `tabId=${tabId}; id=${id}; field=${field}; value=${value}`);
		if (disabledAnyActions)
			return;

		// console.dir(saveData.data);

		var isNewRecord = id == 0;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'save',
				userId: user.id,
				tableName: tableName,
				id: id,
				tabId: tabId,
				field: field,
				value: value,
				// selectedColor: selectedColor
			});

			apiAction(res, (resp) => {
			}, async () => {
				apiActionProcess = false;
				actions.hideLoading();

				await getData(true, isNewRecord ? TABLE_DATES_INIT : null);

				if (GUI.socket) {
					GUI.socket.emit('tablesChanged', {
						userId: user.id,
						tableName: tableName,
					});
				}
			});
			apiActionProcess = false;
		}
	};

	// const onCellFocused = (id, cellName) => {
	//     console.log('cell focus', id, cellName);
	// }

	// const userIsModerator = isModerator(user);
	// let isModerOrCassir = userIsModerator || isUser(user);
	// let content = null;

	return (
		<>
			<ContentHeader
				title={MENU_TITLE.tables.many + ((tableName && MENU_TITLE.tables[tableName])
					? ' / ' + MENU_TITLE.tables[tableName].many
					: '')}
				showDates={true}
				datesAction={onCalendarSelectDate}
				// dateType={ContentHeaderDateType.month}
				// datesAction={async () => {
				//     // console.log(GUI.calendarDates);
				//     setTableDates(GUI.calendarDates);
				//     await getData(true, GUI.calendarDates);
				// }}
			/>


			{
				disabledAnyActions
					? <div className={"tables-preloader"}>
						<i className={"fa fa-circle-o-notch fa-spin"}/>
					</div>
					: null
			}
			{
				Object.keys(data).length > 0
					? (
						<Switch>

							<Route path="/tables/bills">
								<Bills
									user={user}
									{...data}
									onSave={save}
									doTablesApiAction={doTablesApiAction}

									GUI={GUI}
									getData={getData}
									dates={tableDates}
									onSelectMonth={billsOnSelectMonth}

									setSelectOnlyUserId={setSelectOnlyUserIdAction}
									setActiveTabId={changeTab}
								/>
							</Route>
							<Route path="/tables/reportcard">
								<EmployeeReportCard
									user={user}
									{...data}
									onSave={save}
									dates={tableDates}
									doTablesApiAction={doTablesApiAction}
									onSelectMonth={billsOnSelectMonth}
									activeTabId={activeTabId}
									setActiveTabId={changeTab}

									GUI={GUI}
								/>
							</Route>


							<Route component={Page404}/>
						</Switch>
					)
					: null
			}

		</>
	)

};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction, setCalendarDates
		//setSearchAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TablesContainer);
