import React, {useEffect, useState} from 'react';
import {DEBUG} from "utils/config";
import {in_array, isModerator, isUser, hashObject} from "utils/func";
import {Storage} from "utils/Storage";
import {Tabs, TabsById} from "components/_common";
import BillsTable from './BillsTable';
import ManagerSelect from "./ManagerSelect";
import BillsMonths from "./BillsMonths";
import FormItem from "../_common/FormItem";


const BillsSortStorageName = 'tables_bills_sort';
const BillsFilterManagerStorageName = 'tables_bills_filter_manager';

const Bills = props => {
    // console.dir(props);

    const {
        user, GUI,
        tabs = [], columns = [], managers,
        data,
        onSave, setSelectOnlyUserId,
        // selectedColor, setSelectedTableRow,
        doTablesApiAction, getData,
        onSelectMonth,
        dates, //выбранные даты
        setActiveTabId,
    } = props;
    // console.log('Bills', data.length, dataHash);
    // console.dir(tabs);

    const userIsModerator = isModerator(user);
    let isModerOrCassir = userIsModerator || isUser(user);


    const [sort, setSort] = useState(Storage.get(BillsSortStorageName, {field: 'created', asc: false}, true));
    // по https://crm.ghoul.ru/tasks/264  оставляем только 1
    // const sort = {field : 'created', asc: false};
    const [filterManagerId, setFilterManagerId] = useState(parseInt(Storage.get(BillsFilterManagerStorageName, 0)));


    /**
     * Сортировка
     *
     * @param field -поле
     *
     */
    const sortBy = (field) => {
        return;
        console.log('sortBy', field, sort);

        if (field === 'files')
            field = 'send2email';

        let newSort = {field: field, asc: !sort.asc};
        Storage.set(BillsSortStorageName, newSort);
        setSort(newSort);
    }
    // console.dir(data);

    //фоны
    let rowsBackgroundInit = {};
    let cellsBackgroundInit = {};

    if (data && Array.isArray(data)) {
        data.map(row => {
            if (row.bgr !== '') {
                rowsBackgroundInit[row.id] = row.bgr;
            }

            if (row.bgrCells !== null) {
                cellsBackgroundInit[row.id] = row.bgrCells;
            }
            if (row.id == 3222)
                console.dir(row);
        });
    }
    const [rowsBackground, setRowsBackground] = useState(rowsBackgroundInit);
    const [cellsBackground, setCellsBackground] = useState(cellsBackgroundInit);

    // console.log(hashObject(cellsBackground), hashObject(cellsBackgroundInit));
    //пишут что это antipattern, но и похуй
    if (hashObject(cellsBackground) !== hashObject(cellsBackgroundInit))
        setCellsBackground(cellsBackgroundInit);



    let tabsData = [];
    // let tabsIds = {};
    if (tabs && tabs.length) {

        let sortField = sort.field;
        if (sortField == 'manager_id') {
            sortField = 'managerName';
            data.map((row, i) => {

                if (row.manager_id != 0 && managers.hasOwnProperty(row.manager_id))
                    data[i]['managerName'] = managers[row.manager_id].name;
                else
                    data[i]['managerName'] = '';

                return null;
            });
        }


        /*
        data.sort((a, b) => {
            // console.log(a[sort.field], b[sort.field]);
            if (a[sortField] > b[sortField])
                return !sort.asc ? 1 : -1;
            if (a[sortField] < b[sortField])
                return !sort.asc ? -1 : 1;

            return 0;
        });*/


        //раскидываем по вкладкам
        let managersByTabs = {};
        tabs.map((tab) => {

            managersByTabs[tab.id] = [];

            Object.values(managers).map(man => {
                if (tab.divisions.length === 0 || in_array(man.divId, tab.divisions)) {
                    managersByTabs[tab.id].push(man);
                }
                return null;
            });

            return null;
        });

        const dataFiltered = [];
        // const managersFilterManagers = {};
        data.map(row => {
            //упразднено 29/11/2023
            // if (dataByTabs[row.tabId]) {
            //     if (filterManagerId === 0 || row.manager_id === filterManagerId || row.manager_id === 0)
            //         dataByTabs[row.tabId].push(row);
            // }
            if (filterManagerId === 0 || row.manager_id === filterManagerId || row.manager_id === 0)
                dataFiltered.push(row);

            // if (row.manager_id !== 0) {
            //     if (!managersFilterManagers.hasOwnProperty(row.manager_id)) {
            //         managersFilterManagers[ row.manager_id ] = managers[row.manager_id];
            //     }
            // }
            return null;
        });


        let managersFilter = <div className={"tables-filter-item"}>
            <ManagerSelect
                title={filterManagerId === 0 ? 'Только для менеджера' : 'Все менеджеры'}
                managers={managers}
                divisions={[]}
                value={filterManagerId}
                onChange={val => {
                    setFilterManagerId(parseInt(val));
                }}
            />
        </div>

        const BillsMonthComponent = <BillsMonths
            GUI={GUI}
            onClick={onSelectMonth}
            dates={dates}
        />;

        const MyBillsCheckbox = <FormItem
            name={"onlyForMe"}
            fieldType={"bool"}
            label={"Мои счета"}
            changeAction={e => {
                setSelectOnlyUserId(e.target.checked);
            }}
        />;

        console.dir(cellsBackground);

        tabs.map((tab, i) => {
            // tabsIds[i] = tab.id;
            if (isModerOrCassir || in_array(user.divId, tab.divisions))
                tabsData.push({
                    id: tab.id,
                    title: tab.name,
                    content: <BillsTable
                        user={user}
                        tab={tab}
                        columns={columns}
                        editable={true}
                        // data={dataByTabs[tab.id]}
                        data={dataFiltered}
                        onSave={onSave}
                        managers={managersByTabs[tab.id]}
                        managersFilter={managersFilter}
                        doTablesApiAction={doTablesApiAction}
                        // sort={sort}
                        // sortBy={sortBy}
                        GUI={GUI}
                        getData={getData}
                        dates={dates}
                        billsMonths={BillsMonthComponent}
                        MyBillsCheckbox={MyBillsCheckbox}
                        // rowsBackground={rowsBackgroundInit}
                        // cellsBackground={cellsBackgroundInit}

                        rowsBackground={rowsBackground}
                        setRowsBackground={setRowsBackground}
                        cellsBackground={cellsBackground}
                        setCellsBackground={setCellsBackground}
                    />
                });

            return null;
        });

    } else {
        console.error('no tabs');
        return null;
    }


    return (
        <>
            <TabsById
                tabs={tabsData}
                tabPrefix={'bills-tabs'}
                url={'/tables/bills'}

                onChange={tabId => {
                    console.log('change tab', tabId);
                    setActiveTabId(tabId);
                }}
            />
        </>
    )

};

export default Bills;