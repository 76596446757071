import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {USER_LOGOUT, userAuth} from "actions/UserActions";
import {NavLink} from "react-router-dom";
import cn from "classnames";


const sortConnections = (data) => {
	return data.sort((a, b) => {
		return (a.name >= b.name) ? 1 : -1;
	})
}

const TopbarUserinfo = (props) => {

	const {GUI, user, actions} = props;

	const [connections, setConnections] = useState([]);
	const [showConnections, setShowConnections] = useState(false);

	// console.dir(user);

	useEffect(() => {
		// console.log(GUI.socket);
		if (user.role == 'admin' && GUI.socket) {
			GUI.socket.on('connectionsInfo', res => {
				setConnections(sortConnections(res));
			});
		}
	}, [
		GUI.socket,
		user
	]);
	const avatar = (
		<div className={"im"}
			 style={{
				 backgroundImage: "url(" + (
					 (user && user.avatar) ? user.avatar : '/img/user.png'
				 ) + ")"
			 }}>
			<i
				className={"fa fa-" + (GUI.socket ? 'link' : 'unlink')}
				title={(GUI.socket ? 'Подключено' : 'Не подключено') + " к удаленному серверу"}
			></i>
		</div>
	);
	return (
		<div className="tb-user flx -alc">

			{avatar}

			<div className="n" title={user.name}>
				{/*{user.name.length}*/}
				{
					user.name.length > 15
						? user.name.slice(0, 15) + '...'
						: user.name
				}

				<br/>
				<span>{user.roleName}</span>
				<span>{user.division ? ' (' + user.division.name + ')' : ''}</span>
			</div>
			{/*<div className="">*/}
			{/*	<i className={"fa fa-caret-down"}></i>*/}
			{/*</div>*/}
			<div className="actions -right">
				<i className="fa fa-caret-down"></i>
				<div>
					<NavLink to={"/user/profile_edit/"}><i className="fa fa-edit"></i>Редактировать</NavLink>
					<NavLink to={"/settings/"}><i className="fa fa-cogs"></i>Настройки</NavLink>
					{
						user.role == 'admin' ? <a href="#"
						   onClick={(e) => {
							   e.preventDefault();
							   setShowConnections(!showConnections);
						   }}
						>
							<i className="fa fa-users"></i> Online users
						</a> : null
					}
					<a
						href={"#"}
						onClick={(e) => {
							e.preventDefault();
							// console.log('try logout');
							actions.userAuth(USER_LOGOUT, null);
							// history.push('/');
						}}
					>
						<i className="fa fa-close"></i>Выйти
					</a>
				</div>
			</div>

			<div className={cn("online-users", {show: showConnections})}>
				<div className="online-users-content">

					<i className="close fa fa-times"
					   onClick={() => setShowConnections(false)}
					></i>

					<table className={"tbl dark"}>
						<thead>
						<tr>
							<td width={"20%"}>Имя</td>
							<td width={"10%"}>Роль</td>
							<td width={"20%"}>ip</td>
							<td width={"30%"}>ua</td>
							<td width={"20%"}>url</td>
						</tr>
						</thead>
						<tbody>
						{
							connections.map((usr, k) => {

								return <tr key={k}>
									<td>{usr.name} ({usr.id})</td>
									<td>{usr.role}</td>
									<td>{usr.ip.replace('::ffff:', '')}</td>
									<td className={"ou-ua"}>
										{usr.ua}
										{usr.browserWidth ? (
											<>
												<br/>
												<i className={"fa fa-desktop"}></i> {usr.browserWidth}x{usr.browserHeight} px
											</>
										) : null}
									</td>
									<td className={"ou-url"}>{usr.url}</td>
								</tr>
							})
						}
						</tbody>

					</table>
				</div>
			</div>

		</div>
	);
};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		userAuth
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TopbarUserinfo);
