import React, {useEffect, useState} from 'react';
import Api, {apiAction} from "utils/Api";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {setCalendarSelectDateAction} from "actions/GUIActions";
import {connect} from "react-redux";
import {ContentHeader, Button, Win} from "components/_common";
import {in_array, getFileSize, isManagerModerator, isManager, diffDays, showToast, user4api} from "utils/func";
// import {EmployeeReportTable} from "components/user";

const EmployeeReportCardTitle = 'Табель сотрудников';

const API_URL = 'users.php';

//TODO удалить
const EmployeeReportCard = ({user, GUI, actions}) => {

	const [users, setUsers] = useState({});
	const [currentDate, setCurrentDate] = useState(null);


	let apiActionProcess = false;
	const getData = async (isSubscribe = true, calDates = null, search = '') => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;
			const res = await Api.post(API_URL, {
				action: 'getEmployeeReport',
				user: user4api(user),
				dates: calDates ?? GUI.calendarDates
			});

			apiAction(res, (response) => {
				setUsers(response.users);
				setCurrentDate(response.currentDate);
				// console.dir(response);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
			apiActionProcess = false;
		}
		actions.hideLoading();
	};
	const onCalendarSelectDate = (dates) => {
		console.log('onCalendarSelectDate');
		getData(true, dates).then(null);
	};

	useEffect(() => {
			let isSubscribe = true;
			getData(isSubscribe).then(null);
			// actions.setCalendarSelectDateAction(onCalendarSelectDate);

			return () => {
				isSubscribe = false;
				//отключаем функцию обработчик на календаре
				// actions.setCalendarSelectDateAction(null);
			}
		},
		[]
	);

	return (
		<>
			<ContentHeader
				title={EmployeeReportCardTitle}
				showDates={true}
				datesAction={onCalendarSelectDate}
			/>
			<Win
				header={EmployeeReportCardTitle}
			>
				{/*{*/}
				{/*	currentDate !== null*/}
				{/*		? <EmployeeReportTable*/}
				{/*			date={currentDate}*/}
				{/*			users={users}*/}
				{/*		/>*/}
				{/*		: null*/}
				{/*}*/}

			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeReportCard);



