import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import date from "php-date";
import * as strtotime from "strtotime";
import {rolesAdmin, rolesModerator, SITE_URL} from "utils/config";
import {
	in_array, isModerator,
	telHref
} from "utils/func";
import {Button} from "components/_common";
import {
	isEditableTask,
	driverSelectComponent,
	installersSelectComponent,
	expectedTaskInstallers,
	ToolsIcons,
	canSendSms, canSetTaskInstallers
} from "./utils";
import cn from "classnames";
import {isMobile} from 'react-device-detect';
import * as uniqid from "uniqid";
import TasksListTableRowPrices from "./TasksListTableRowPrices";

const TasksListTableRow = props => {
	const {
		user,
		// GUI,
		onSave, onSendSms,
		columnsTitle,
		task, todoList, drivers, installers,
		todoDostavkaCode = [], todoInstallCode = [],
		// todoTwoInstaller = [],
		// peremeshenie = false,
		users = {},
		TABLE_ROW_NUM,
		showPopup,
		onSetField
	} = props;

	let tkInfo = [],
		// opisanie = [],
		conts = [],
		adr = [],
		// adrHasTodo = false,
		execs = [],
		prim = [];

	// console.dir(task);

	let userIsModerator = isModerator(user);

	let {driverId = -1,
		installers: taskInstallers = [],
		install = {},//инфа о монтажах
		deliveryInfo = {},
		moreCompany = []
	} = task;

	if (deliveryInfo === null)
		deliveryInfo = {};

	let {
		installers_max = 0,
		time: deliveryTime = ''
	} = deliveryInfo;
	installers_max = parseInt(installers_max);

	const [taskState, setTaskState] = useState(task);
	//какое поле редактируем
	const [editField, setEditField] = useState('');
	// if (task.id == 27040)
	// 	console.dir(taskState);

	//можно редактировать задачу, проверка по дате
	const isEditable = isEditableTask(task.ddateReal, task.actualDays);

	const sendSmsRoles = user.settings.g_send_sms_roles || rolesAdmin;
	let showSmsButton = isEditable && in_array(user.role, sendSmsRoles) && task.phoneMobile != '';

	if (task.tkInfo) {
		if (task.tkInfo.strahovka != undefined)
			tkInfo.push(<div className="razgr" key={'strah-' + task.id}><i className="fa fa-rub"></i> Страховка</div>);
		if (task.tkInfo.obreshetka != undefined)
			tkInfo.push(<div className="razgr" key={'obresh-' + task.id}><i className="fa fa-slack"></i> Обрешетка
			</div>);
		if (task.tkInfo.dostavka2door != undefined)
			tkInfo.push(<div className="razgr" key={'d2d-' + task.id}><i className="fa fa-map-marker"></i> Адресная
				доставка</div>);
	}

	if (task.todo in todoList) {

		let installersText = '';
		if (installers_max)
			installersText = <span className={"clr-red"}>
				({installers_max}М)
			</span>;
		adr.push(
			<div key="adres-todo"
				 className={"-bt"}>
				<i className={"fa fa-dot-circle-o"}/>
				{
					(deliveryTime === 'night')
						? ' Ночная'
						: null
				}
				{' ' + todoList[task.todo].name}
				{installersText}
			</div>
		);
	}
	else if (task.todo === '')
		adr.push(<div key="adres-todo"
					  className={"-bt"}>
			<i className={"fa fa-dot-circle-o"}/> НЕ НАЗНАЧЕНО
		</div>);

	// console.dir(task);

	if (task.tkInfo && task.tkInfo.tkName != undefined && task.tkInfo.tkName != '')
		adr.push(
			<div key={'tl-row-tk-info'}
				 className={"-bt"}>
				ТК: {task.tkInfo.tkName}
			</div>
		);
	if (parseInt(task.mkadKm))
		adr.push(
			<div key={"adr-mkad"}
				 className={"-bt"}>
				от МКАД {task.mkadKm} км
			</div>
		);

	if (task.email != '')
		adr.push(
			<div key={"email-" + task.id}
				 className={"-bt"}>
				<a href={"mailto:" + task.email}>{task.email}
				</a>
			</div>
		);

	adr = adr.concat(tkInfo);

	if (deliveryInfo && (deliveryInfo.tools || deliveryInfo.toolsOther)) {
		let toolsBox = [];
		let toolsIcons = [];
		// console.log(task.deliveryInfo.tools);
		if (deliveryInfo.tools)
			deliveryInfo.tools.map(tool => {
				toolsIcons.push(
					<img src={ToolsIcons[tool]} key={"tool-" + tool}/>
				);
			});
		if (toolsIcons.length)
			toolsBox.push(
				<div key={"tools"} className={"tools"}>
					{toolsIcons}
				</div>
			)

		if (deliveryInfo.toolsOther && deliveryInfo.toolsOther !== '')
			toolsBox.push(
				<div key={"toolsOther-" + task.id}>
					{deliveryInfo.toolsOther}
				</div>
			);

		adr.push(
			<div key={"tools-" + task.id}>
				{toolsBox}
			</div>);
	}

	//показать кнопку сохранения водителей/монтажников
	let showSaveExecButton = false;

	const execDocsButton = execId => {
		return <NavLink
			key={"ds-docs-" + execId}
			to={"/docs/user/" + execId}
			// target={"_blank"}
			className={"docs-link no-print"}
			onClick={e => {
				e.preventDefault();
				showPopup({action: 'showUserInfo', userId: execId});
			}}
		>
			информация
		</NavLink>
	};

	const [driverDocsBtn, setDriverDocsBtn] = useState(
		taskState.driverId ? execDocsButton(taskState.driverId) : null
	);

	let driverSelect = [];

	if (in_array(task.todo, todoDostavkaCode)) {

		if (isEditable) {
			let ds = driverSelectComponent(
				user.role,
				drivers,
				driverId,
				(e) => {
					let tsk = {...taskState};
					// let tsk = taskState;
					tsk.driverId = e.target.value;
					setTaskState(tsk);
					// console.log(tsk.driverId);
					setDriverDocsBtn(
						execDocsButton(tsk.driverId)
					);
				}
			);

			if (ds) {
				showSaveExecButton = true;

				driverSelect.push(
					<div key={"ds-" + task.id} className="no-print">
						{ds}
					</div>
				);
				if (drivers[driverId])
					driverSelect.push(
						<div className="print-only" key={"driver-name-print"}>
							{drivers[driverId].name + ' (' + drivers[driverId].phone + ')'}
						</div>
					);
				driverSelect.push(driverDocsBtn);
			}

		}

		let deliveryEnded = <div className={cn("-install-ended", {'-no': task.deliveryEnded == 0})}
								 title={task.deliveryEnded == 0 ? "Доставка не завершена" : "Доставка завершена"}></div>

		if (showSaveExecButton && driverSelect) {
			execs.push(
				<div className="form-item -lbl-normal driver-info " key={"tl-driver" + driverId}>
					<label>Водитель {deliveryEnded}</label>
					{driverSelect}
				</div>
			);
		}
		else if (drivers[driverId])
			execs.push(
				<div className="form-item driver-info" key={"tl-driver" + driverId}>
					<label>Водитель {deliveryEnded}</label>
					{drivers[driverId].name + ' (' + drivers[driverId].phone + ')'}
					<br/>
					<span className="no-print">{driverDocsBtn}</span>
				</div>
			)
	}

	let installersDocsButtonsInit = [];
	if (taskInstallers.length) {
		for (let tiId of taskInstallers) {
			installersDocsButtonsInit.push(
				execDocsButton(tiId)
			);
		}
	}

	const setSetInstallersDocsButtons = (data) => {
		let res = [];
		for (let id of data) {
			res.push(
				execDocsButton(id)
			);
		}
		return res;
	};
	const [installersDocsButtons, setInstallersDocsButtons] = useState(
		setSetInstallersDocsButtons(taskInstallers)
	);

	/**
	 * Монтажники
	 */
	if (in_array(task.todo, todoInstallCode)) {
		// const installersMax = taskInstallers.length + 1;
		let installersMax = 0;
		if (deliveryInfo && deliveryInfo.installers_max !== undefined)
			installersMax = parseInt(deliveryInfo.installers_max);

		let installersSelect = [];
		let installersPrint = [];

		if (installersMax)
			[installersSelect, installersPrint] = installersSelectComponent(
				user.role,
				isEditable,
				installersMax,
				installers,
				taskInstallers,
				(e, i) => {
					let tsk = {...taskState};
					if (tsk.installers == undefined)
						tsk.installers = [];
					tsk.installers[i] = e.target.value;
					setTaskState(tsk);

					setInstallersDocsButtons(
						setSetInstallersDocsButtons(tsk.installers)
					)
				},
				installersDocsButtons
			);

		if (installersSelect.length) {
			execs.push(
				<div className="form-item clr-red -lbl-normal" key={"tl-installers"}>
					<label className={'clr-red'}>
						<div className="flx -sb">
							Монтажник{installersMax > 1 ? 'и' : ''}

							<div className={cn("-install-ended", {'-no': task.installEnded == 0})}
								 title={task.installEnded == 0 ? "Монтаж не сдан" : "Сдан, оценка монтажа"}>
								{/* оценок нет давно, скрыл 30/06/23*/}
								{/*{*/}
								{/*	task.installRating > 0*/}
								{/*		? task.installRating*/}
								{/*		: null*/}
								{/*}*/}
							</div>
						</div>
					</label>
					<div className="no-print">
						{installersSelect}
					</div>
					<div className="print-only">
						{installersPrint}
					</div>

				</div>
			);
			showSaveExecButton = true;
		}
	}

	if (todoList[task.todo] && todoList[task.todo].noSms)
		showSmsButton = false;
	//19/05/23
	if (!canSendSms(task))
		showSmsButton = false;

	if (showSaveExecButton)
		execs.push(
			<Button
				key={'tl-save-' + task.id}
				onClick={e => onSave(taskState)}
				title={"Сохранить"}
				ico={"save"}
				type={"apply"}
			/>
		);

	/// Документы готовы
	if (in_array(user.role, [...rolesModerator, 'user', 'moderator'])) {
		execs.push(
			<div className="docs-ready" key={'docs-ready'}>
				<Button
					onClick={e => {
						taskState.docsReady = taskState.docsReady == 1 ? 0 : 1;
						onSave(taskState, 'docsReady');
					}}
					title={"Документы готовы"}

					ico={(taskState.docsReady == 1 ? 'check-' : '') + "square-o"}
					cls={"-small " + (taskState.docsReady == 0 ? '-lgrey' : '-green')}
				/>
			</div>
		);
	}

	if (in_array(user.role, [...rolesModerator, 'driver']) && taskState.docsReady == 1) {
		prim.push(
			<div className="docs-ready" key={'docs-ready'}>
				Документы готовы
			</div>
		);

	}

	let taskNum = task.id;
	if (user.role == 'installer')
		taskNum = <NavLink
			to={`/tasks/install/${task.id}`}>
			{task.id}
		</NavLink>;
	else if (user.role == 'driver' && task.driverId == user.id)
		taskNum = <NavLink
			to={`/tasks/delivery/${task.id}`}>
			{task.id}
		</NavLink>;
	else if (in_array(user.role, rolesModerator)
		|| task.divId == user.divId
		|| in_array(task.todo, ['snab', 'pocht-sv', 'sv-pocht', 'buy', 'storekeeper-bor', 'storekeeper-kol'])
	)
		taskNum = <NavLink
			to={`/tasks/edit/${task.id}`}>
			{task.id}
		</NavLink>;

	let rowClass = ['tr'];
	if (task.todo === '')
		rowClass.push('not-assign');

	if (expectedTaskInstallers(task))
		rowClass.push('hasInstallers');

	let smsButton = null;
	if (showSmsButton)
		smsButton = (
			<div key={"task-sms-send-btn-" + task.id}>
				<Button
					key={'phoneMobile-sms-' + task.id}
					onClick={e => onSendSms(task.id)}
					title={"SMS"}
					tip={"Отправить SMS на номер: " + task.phoneMobile}
					ico={"envelope"}
					// type={"save"}
					cls={"-small " + (task.smsSendDate ? '-lgrey' : '-green')}
				/>
				{task.smsSendDate ? (
					<div className={"tl-sms-send"}>
						Отправлено {date('d.m.Y H:i', new Date(task.smsSendDate * 1000))}
						{users[task.smsUserId] ? ', ' + users[task.smsUserId].name : null}
					</div>
				) : null}
			</div>
		);

	let trStyle = {
		backgroundColor: (user.settings && user.settings.task_list_bgr && user.settings.task_list_bgr[task.todo])
			? user.settings.task_list_bgr[task.todo] : '#ffffff'
	};

	const companyInfoItem = (key, label, val, editable = false, ico = null) => {

		if (key == 'phoneMobile' && isMobile)
			val = <a href={`tel:+7${telHref(val)}`}>{val}</a>

		if (editable)
			val = (
				<div
					key={'phoneMobile-' + task.id}
					className={"tl-edit-field"}
				>
					<div className="-field"
						 contentEditable={true}
						 suppressContentEditableWarning={true}
						 onBlur={async (e) => {

							 if (task[key] != e.currentTarget.innerText.trim()) {
								 await onSetField(task.id, key, e.currentTarget.innerText.trim());
							 }
							 setEditField('');
						 }}
						 onMouseEnter={e => setEditField(`${key}${task.id}`)}
					>{task.phoneMobile}</div>

					<i
						className={"fa fa-edit"}
						onClick={e => setEditField(key)}
					></i>
				</div>
			);

		return <div className={"sklad-tasks-list-company-item " + key} key={uniqid()}>
			<label>
				{ico}
				{label}:
			</label>
			<div>
				{val}
			</div>
		</div>
	}

	const companyFields = [
		{key: 'company', label: 'Компания'},
		{key: 'name', label: 'Контактное лицо'},
		{key: 'nakl', label: 'Номера накладных'},
		{key: 'market_num', label: 'Номера заданий'},
		{key: 'phoneMobile', label: 'Моб.', editable: false},
		{key: 'phones', label: 'Доп. телефоны'},
		{key: 'cmnts', label: 'Примечание'},
		{key: 'address', label: 'Адрес', ico: <i className={"fa fa-map-marker"}/>},
	];
	const companyInfo = [];

	const companyInfoMain = [];
	companyFields.map(({key, label, editable = false, ico = null}) => {
		if (task[key] !== undefined && task[key] !== '')
			companyInfoMain.push(companyInfoItem(key, label, task[key], editable, ico));
	});
	if (companyInfoMain.length)
		companyInfo.push(
			<div className={"stl-comp-box"} key={`strlcb-main`}>
				{companyInfoMain}
			</div>
		);

	if (moreCompany && typeof moreCompany == 'object' && moreCompany.length) {
		// companyInfo.push(<h5 key={uniqid()}>---</h5>);

		moreCompany.map((comp, i) => {
			let companyInfoAdd = [];

			companyFields.map(({key, label, editable = false}) => {
				if (comp[key] !== undefined && comp[key] !== '')
					companyInfoAdd.push(companyInfoItem(key, label, comp[key], editable));
			});

			if (companyInfoAdd.length)
				companyInfo.push(
					<div className={"stl-comp-box"} key={`strlcb-main-${i}`}>
						{companyInfoAdd}
					</div>
				);
		});

	}

	// console.log(task.id, 'taskInstallers:' + taskInstallers.length);

	let setPrices = null;
	if (
		// taskInstallers.length
		installers_max
		&&
		userIsModerator
		&&
		canSetTaskInstallers(
			task.todo,
			todoInstallCode,
			(deliveryInfo && deliveryInfo.type) ? deliveryInfo.type : ''
		)
	) {

		setPrices = <TasksListTableRowPrices
			taskId={task.id}
			canEdit={true}
			install={install}
			onSave={onSave}
		/>
	}

	return (
		<ul
			className={cn(rowClass)}
			style={trStyle}
		>
			<li className={"td col-num halfed"}>
				<h5 className="td-title">
					{columnsTitle.ndate}
				</h5>
				<div className="half-cont">
					<div>
						{taskNum}
					</div>
					<div title={"Дата создания"} className={"-date -bt"}>
						{date('d.m.y H:i', new Date(strtotime(task.created) * 1000))}
					</div>
					<div title={"Дата доставки"} className={"-date -bt"}>
						{date('d.m.y', new Date(strtotime(task.ddateReal) * 1000))}
					</div>

					{smsButton}
				</div>


				{/*    if (smsButton)*/}
				{/*    res.push(<div className={"slt-comp-sms _tac"}>*/}
				{/*    {smsButton}*/}
				{/*</div>);*/}

				<div className="-row">
					{TABLE_ROW_NUM}
				</div>
			</li>
			<li className={"td col-company"}>
				<h5 className="td-title">
					{columnsTitle.company}
				</h5>
				{companyInfo}
				{setOpisanie(user, task, smsButton, editField, setEditField, onSetField)}
				{/*<div className="half-cont">*/}
				{/*	<div>*/}
				{/*		{task.company}*/}
				{/*	</div>*/}
				{/*	{*/}
				{/*		task.nakl && (*/}
				{/*			<div className={"-bt"}>*/}
				{/*				Накладные:<br/>*/}
				{/*				<b>{task.nakl}</b>*/}
				{/*			</div>*/}
				{/*		)*/}
				{/*	}*/}
				{/*</div>*/}
			</li>
			{/*<li className={"td col-op halfed"}>*/}
			{/*	<h5 className="td-title">*/}
			{/*		{columnsTitle.op}*/}
			{/*	</h5>*/}
			{/*	<div className="half-cont">*/}
			{/*		{setOpisanie(user, task, smsButton, editField, setEditField, onSetField)}*/}
			{/*	</div>*/}
			{/*</li>*/}

			{/*<li className={"td col-cont halfed"}>*/}
			{/*	<h5 className="td-title">*/}
			{/*		{columnsTitle.contacts}*/}
			{/*	</h5>*/}
			{/*	{conts}*/}
			{/*</li>*/}
			<li className={"td col-adr halfed"}>
				<h5 className="td-title mt-10">
					{columnsTitle.address}
				</h5>
				<div className="half-cont">
					{adr}
					{setPrices}

				</div>
			</li>
			<li className={cn("td col-execs")}>
				<h5 className="td-title mt-10">
					{columnsTitle.execs}
				</h5>
				{execs}

			</li>
			<li className={"td -poseshen"}>
				{prim}
			</li>
		</ul>
	);

};

export default TasksListTableRow;

const setOpisanie = (user, task, smsButton, editField, setEditField, onSetField) => {
	let res = [];
	// let customer = [];

	if (in_array(user.role, [...rolesModerator, 'logist', 'manager'])) {

		/*
		if (task.name)
			customer.push(
				<div className="adres-name" key={"adres-name"}>
					{task.name}
				</div>
			);


		if (task.phoneMobile != '') {

			if (isMobile)
				customer.push(
					<div
						key={'phoneMobile-' + task.id}
						className={""}
					>
						Моб.: <a href={`tel:+7${telHref(task.phoneMobile)}`}>{task.phoneMobile}</a>
					</div>
				);
			else
				customer.push(
					<div
						key={'phoneMobile-' + task.id}
						className={"tl-edit-field"}
					>
						Моб.:
						<div className="-field"
							 contentEditable={editField == `phoneMobile${task.id}`}
							 suppressContentEditableWarning={true}
							 onBlur={async (e) => {

								 if (task.phoneMobile != e.currentTarget.innerText.trim()) {
									 await onSetField(task.id, 'phoneMobile', e.currentTarget.innerText.trim());
								 }
								 setEditField('');
							 }}
							 onMouseEnter={e => setEditField(`phoneMobile${task.id}`)}
						>{task.phoneMobile}</div>

						<i
							className={"fa fa-edit"}
							onClick={e => setEditField('phoneMobile')}
						></i>
					</div>
				);

		}


		if (task.phones != '') {
			customer.push(
				<div
					key={'phones-' + task.id}
					className={"tl-edit-field"}
				>
					Тел.:
					<div className="-field"
						 contentEditable={editField == `phones${task.id}`}
						 suppressContentEditableWarning={true}
						 onBlur={async (e) => {

							 if (task.phones != e.currentTarget.innerText.trim()) {
								 await onSetField(task.id, 'phones', e.currentTarget.innerText.trim());
							 }
							 setEditField('');
						 }}
						 onMouseEnter={e => setEditField(`phones${task.id}`)}
					>{task.phones}</div>

					<i
						className={"fa fa-edit"}
						onClick={e => setEditField('phoneMobile')}
					></i>
				</div>
			);
		}
		// customer.push(<div key={'phones-' + task.id}>Тел.: {task.phones}</div>);
		*/

		// customer.push(smsButton);
		//
		// res.push(
		//     <div className="" key={"tl-customer"}>
		//         {customer}
		//     </div>
		// );

		// if (smsButton)
		//     res.push(<div className={"slt-comp-sms _tac"}>
		//         {smsButton}
		//     </div>);

		if (task.fileUrl && task.fileUrl !== '')
			res.push(<div key={'fileUrl-' + task.id} className={"no-print"}>
				<a href={task.fileUrl} target={"_blank"}>
					<i className="fa fa-download"></i> {task.fileName}
				</a>
			</div>);
	}

	// res.push(
	// 	<div className="-comments" key={"col-op-cmnts"}>
	// 		{task.cmnts}
	// 	</div>
	// )

	// console.dir(task);

	if (parseInt(task.payOnPlace)) {
		res.push(
			<div className="oplata-na-meste _tac" key={"oplata-na-meste"}>
				<div>$$$$ ОПЛАТА НА МЕСТЕ $$$$</div>
				{
					(task.deliveryInfo && task.deliveryInfo.payOnPlacePrice)
						? <div className="oplata-na-meste-price">
							Забрать {task.deliveryInfo.payOnPlacePrice} руб.
						</div> : null
				}
			</div>
		);

	}

	if (parseInt(task.razgr))
		res.push(
			<div className="razgr " key={"razgr"}>
				<div className="_tac">
					<i className="fa fa-sign-out"></i> РАЗГРУЗКА
				</div>
				<div className="flx -c">
					{
						(task.deliveryInfo)
							? <div className="razgr-info">
								<b>Лифт:</b>
								{
									(task.deliveryInfo.lift) ? ' есть' : ' нет'
								}
							</div> : null
					}
					{
						(task.deliveryInfo && task.deliveryInfo.floor)
							? <div className="razgr-info">
								<b>Этаж:</b> {task.deliveryInfo.floor}
							</div> : null
					}
				</div>
			</div>
		);

	return res.length ? (
		<div className={"slt-comp-opisanie"}>
			{res}
		</div>

	) : null;
}
