import React, {useState} from 'react';
import date from "php-date";
import * as strtotime from "strtotime";
import cn from "classnames";
import {toastr} from "react-redux-toastr";
import {FormItem,} from "components/_common";
import {in_array, getFileIcon, array_unset, downloadFile, copyClipboard, isManagerModerator} from "utils/func";
import {rolesModerator, rolesAdmin, MESSAGES} from "utils/config";
import {
	driverSelectComponent,
	installersSelectComponent,
	ToolsIcons,
	canSetTaskInstallers
} from "components/tasks/utils";
import TasksCompany, {TasksCompanyFields} from "components/tasks/TasksCompany";

const TasksEdit = props => {

	const {
		GUI,
		user,
		edit = true,
		formRef, attachFileRef,
		// task,
		tasksData, setTasksData,
		selectCompanyDialog,
		deleteFile,
		//showMkadDialog,
		filesList = [],
		isCopy = false, copyDate = null,
		paramTodo = '',
		changeFieldsAction,//для истории изменений полей
		// todoList = {}, predefined = null,
		// drivers = {}, installers = {},
		// todoDostavkaCode = [],
		// todoInstallCode = [], todoTwoInstaller = [],
	} = props;

	// const isAdmin = in_array(user.role, rolesAdmin);

	const {
		todoList, predefined,
		drivers, installers,
		todoDostavkaCode = [],
		todoInstallCode = [], todoTwoInstaller = [],
		install = null
	} = tasksData;

	const [task, setTask] = useState(tasksData.task);
	const [todo, setTodo] = useState(task.todo ? task.todo : '');

	// console.dir(task);

	const isNewTask = task.id === undefined || task.id === 0;

	let hideFieldsInit = [];
	if (todo === '') {
		hideFieldsInit.push('tkName');
		hideFieldsInit.push('tkInfo');

	}
	else if (todoList[todo] && todoList[todo].hideFields) {
		hideFieldsInit = todoList[todo].hideFields;
	}

	let requireFields = [];
	if (todoList[todo] && todoList[todo].requireFields)
		requireFields = todoList[todo].requireFields;

	const [hideFields, setHideFields] = useState(hideFieldsInit);

	const changeTaskData = e => {

		const {name, type} = e.target;
		let value = e.target.value;
		console.log(`changeTaskData name=${name} type=${type} value=${value}`);

		if (type === 'checkbox') {
			if (!e.target.checked) {

				delete tasksData.task[name];
			}
			else
				tasksData.task[name] = value;
		}
		else {
			tasksData.task[name] = value;
		}

		setTasksData(tasksData);

		if (name === 'todo') {

			setTodo(value);
			setHideFields(
				todoList[value] ? todoList[value].hideFields : hideFieldsInit
			);

			// setSelectedInstallersCount(
			//     in_array(value, ['instself', 'sborka_sklad', 'delivery']) ? 1 : 0
			// );
		}
	};

	let todoOptions = {
		"": {
			value: 'выберите действие'
		}
	};
	// console.log(todoList);
	Object.keys(todoList).map(key => {

		if (todoList[key] == null) {
			console.error(`Не задан тип '${key}' для 'Что делаем'`);
			return;
		}
		// console.log(key);
		const isHidden = (todoList[key] != null && todoList[key].hideInTodo !== undefined) ? todoList[key].hideInTodo : false;

		if (!isHidden && in_array(user.role, todoList[key].roles)) {

			let itm = {
				value: todoList[key].name
			};

			if (todoList[key].selectStyle) {
				itm['selectStyle'] = {
					backgroundColor: todoList[key].selectStyle[0],
					color: todoList[key].selectStyle[1]
				};
			}
			todoOptions[key] = itm;
		}

		return null;
	});

	let companySelectContent = (

		<div
			className="select-company-dialog-btn"
			onClick={e => selectCompanyDialog('client')}>
			Выбрать клиента
		</div>
	);
	if (todo === 'snab')
		companySelectContent = (
			<div
				className="select-company-dialog-btn"
				onClick={e => selectCompanyDialog('postavshik')}>
				Выбрать поставщика
			</div>
		);

	// console.log(task.payOnPlace);
	// console.log('in_array payOnPlacePrice', in_array('payOnPlacePrice', hideFields));

	const dop = [];

	dop.push(
		<div className={"form-chbox-inline"} key={"task-pay-on-place"}>
			<input
				id={"payOnPlace"}
				type={"checkbox"}
				name={"payOnPlace"}
				value={"1"}
				defaultChecked={(task.payOnPlace && task.payOnPlace == 1)}
				disabled={!edit}

				onChange={({target}) => {
					let hf = [...hideFields];

					if (target.checked)
						hf = array_unset(hf, 'payOnPlacePrice');
					else
						hf.push('payOnPlacePrice');

					setHideFields(hf);
				}}
			/>
			<label htmlFor={"payOnPlace"}>Оплата на месте</label>
		</div>
	);
	// console.log(task.deliveryInfo );
	if (
		(task.payOnPlace && task.payOnPlace == 1)
		||
		!in_array('payOnPlacePrice', hideFields)
	) {

		if (!task.deliveryInfo)
			task.deliveryInfo = {
				payOnPlacePrice: ''
			};

		dop.push(
			<div
				className={"form-chbox-inline"}
				key={"task-pay-on-place-price"}>
				<input
					type={"text"}
					name={"payOnPlacePrice"}
					placeholder={"Сумма к оплате*"}
					value={task.deliveryInfo.payOnPlacePrice}
					disabled={!edit}
					required={in_array("payOnPlacePrice", requireFields)}
					onChange={({target: {value}}) => {
						// console.log(value);
						const newTask = {...task};
						newTask.deliveryInfo['payOnPlacePrice'] = value;
						setTask(newTask);
					}}
				/>
			</div>
		);
	}

	dop.push(
		<div className={"form-chbox-inline"} key={"task-razgr"}>
			<input
				id={"razgr"}
				type={"checkbox"}
				name={"razgr"}
				value={"1"}
				defaultChecked={(task.razgr && task.razgr == 1)}
				disabled={!edit}
				onChange={({target}) => {
					let hf = [...hideFields];

					if (target.checked)
						hf = array_unset(hf, 'razgruzka_dop');
					else
						hf.push('razgruzka_dop');

					setHideFields(hf);
				}}
			/>
			<label htmlFor={"razgr"}>Разгрузка</label>
		</div>
	);

	if (!in_array('razgruzka_dop', hideFields)) {
		dop.push(
			<div className={"form-chbox-inline"} key={"task-razgr-lift"}>
				<input
					id={"lift"}
					type={"checkbox"}
					name={"lift"}
					value={"1"}
					defaultChecked={(task.lift && task.lift == 1)}
					disabled={!edit}
				/>
				<label htmlFor={"lift"}>Лифт</label>
			</div>
		);
		dop.push(
			<div className={"form-chbox-inline"} key={"task-razgr-floor"}>
				<input
					type={"text"}
					name={"floor"}
					placeholder={"Этаж"}
					value={task.floor ? task.floor : ''}
					disabled={!edit}
					required={in_array("floor", requireFields)}
					onChange={({target: {value}}) => {
						setTask(prev => ({
							...prev,
							floor: value
						}));
					}}
				/>
			</div>
		);
	}

	const deliveryInfo = task.deliveryInfo !== undefined ? task.deliveryInfo : {};
	const [deliveryType, setDeliveryType] = useState(
		deliveryInfo.type ? deliveryInfo.type : 'msk'
	);
	//parseInt(user.divId) === DIVISION_ID_SVIBLOVO &&  - было до 09/12/22
	if (deliveryType == 'mo')
		requireFields.push('mkadKm');
	else
		requireFields = array_unset(requireFields, 'mkadKm');

	// if (in_array(deliveryType, ['mp_msk', 'mp_tk']))
	//     requireFields = array_unset(requireFields, 'nakl');
	// else
	//     requireFields.push('nakl');

	let deliveryFields = [];
	if (todo === 'delivery') {
		/**
		 * 13/02/2025
		 * Добавляя сюда  вид доставки,
		 * обязательно прописать (или нет) в отправку СМС сюда:
		 *  core/tasks.php::sendTasksSMS()
		 * !! Узнать у клиента  надо ли отправлять СМС
		 */
		deliveryFields.push(
			<FormItem
				key={"deliveryType"}
				edit={edit}
				name={"deliveryType"}
				label={""}
				fieldType={"radio"}
				isRequire={true}
				radioInNewLine={false}
				defVal={deliveryType}
				options={{
					msk: {
						value: 'Москва'
					},
					mo: {
						value: 'Область'
					},
					tk: {
						value: 'ТК'
					},
					mp_msk: {
						value: 'Маркетплейс Москва'
					},
					mp_tk: {
						value: 'Маркетплейс ТК'
					},
				}}
				changeAction={e =>
					setDeliveryType(e.target.value)
				}
			/>
		);
		if (deliveryType === 'mo')
			deliveryFields.push(
				<FormItem
					key={"mkadKm"}
					edit={edit}
					name={"mkadKm"}
					label={"Расстояние от МКАД, км"}
					placeholder={"Сколько км от МКАД"}
					defVal={task && task.mkadKm}
					isRequire={in_array("mkadKm", requireFields)}
					// hideFields={hideFields}
					descr={(
						<a href="https://www.online-perevozka.ru/tablica-rasstojanij.html" target="_blank">
							Выбрать на карте (откроется в новом окне)
						</a>
					)}
					ico={"road"}
				/>
			);

		const tkInfo = task.tkInfo !== undefined ? task.tkInfo : {
			type: ''
		};

		if (deliveryType.indexOf('tk') !== -1) {

			let tkInfoChecked = (key) => {
				return (tkInfo[key] !== undefined && tkInfo[key]) || isNewTask;
			}

			const tkDop = (
				<>
					<div className={"form-chbox-inline"}>
						<input
							id={"strahovka"}
							type={"checkbox"}
							name={"strahovka"}
							value={"1"}
							defaultChecked={tkInfoChecked('strahovka')}
							disabled={!edit}
						/>
						<label htmlFor={"strahovka"}>Страховка</label>
					</div>
					<div className={"form-chbox-inline"}>
						<input
							id={"obreshetka"}
							type={"checkbox"}
							name={"obreshetka"}
							value={"1"}
							defaultChecked={tkInfoChecked('obreshetka')}
							disabled={!edit}

						/>
						<label htmlFor={"obreshetka"}>Обрешетка</label>
					</div>
					<div className={"form-chbox-inline"}>
						<input
							id={"dostavka2door"}
							type={"checkbox"}
							name={"dostavka2door"}
							value={"1"}
							defaultChecked={tkInfoChecked('dostavka2door')}
							disabled={!edit}
						/>
						<label htmlFor={"dostavka2door"}>Адресная доставка</label>
					</div>
				</>
			);

			deliveryFields.push(
				<FormItem
					key={"tkName"}
					edit={edit}
					name={"tkName"}
					label={"Транспортная компания"}
					defVal={(task && task.tkInfo && task.tkInfo.tkName) ? task.tkInfo.tkName : ""}
					// changeAction={changeTaskData}
					isRequire={in_array("tkName", requireFields)}
					// hideFields={hideFields}
					predefined={predefined ? predefined.tkName : []}
					ico={"truck"}
				/>
			);
			deliveryFields.push(
				<FormItem
					key={"tkInfo"}
					name={"tkInfo"}
					edit={edit}
					fieldType={tkDop}
					label={"Дополнительные услуги транспортной компании"}
					// hideFields={hideFields}
				/>
			);
		}
		else
			deliveryFields.push(
				<FormItem
					key={"deliveryTime"}
					edit={edit}
					name={"deliveryTime"}
					label={""}
					fieldType={"radio"}
					isRequire={true}
					radioInNewLine={false}
					defVal={deliveryInfo.time ? deliveryInfo.time : 'day'}
					options={{
						day: {
							value: 'День',
							ico: 'sun-o'
						},
						night: {
							value: 'Ночь',
							ico: 'moon-o'
						}
					}}
					changeAction={e => {

					}}
				/>
			);

		if (deliveryType !== 'mp_tk')
			deliveryFields.push(
				<FormItem
					key={"dop"}
					name={"dop"}
					edit={edit}
					fieldType={dop}
					label={"Дополнительно"}
				/>
			);
	}

	// console.dir(task);

	// console.log(todo, deliveryType);
	let cmntsPredefined = (predefined && predefined.cmnts) ? predefined.cmnts : [];
	if (todoList[todo] && todoList[todo].predefined_cmnts) {
		cmntsPredefined = todoList[todo].predefined_cmnts;

		if (todo === 'delivery'
			&& deliveryType !== ''
			&& todoList[todo]['subtype'] && todoList[todo]['subtype'][deliveryType]
			&& todoList[todo]['subtype'][deliveryType]['predefined_cmnts']
		) {
			cmntsPredefined = todoList[todo]['subtype'][deliveryType]['predefined_cmnts'];
		}
	}

	///MANAGER
	let managerName = '';
	if (!task.id)
		managerName = user.name;
	else if (task.uName)
		managerName = task.uName;

	let driverSelect = null;
	if (in_array(todo, todoDostavkaCode)) {

		if (edit) {
			const cd = driverSelectComponent(
				null,
				drivers,
				(task.drivers && task.drivers.length) ? task.drivers[0] : 0,
				null
			);
			if (in_array(user.role, ['logist', ...rolesModerator]))
				driverSelect = <FormItem
					edit={edit}
					name={"driver"}
					fieldType={cd}
					label={"Водитель"}
					ico={"car"}
				/>;
		}
	}
	// console.log(deliveryInfo);
	// console.log(deliveryType);
	const [selectedInstallersCount, setSelectedInstallersCount] = useState(
		deliveryInfo.installers_max ? parseInt(deliveryInfo.installers_max) : 1
	);
	// console.log(selectedInstallersCount);

	// const [unusedInstallers, setUnusedInstallers] = useState(installers);
	// console.log(unusedInstallers);
	let installersSelect = [];
	// if (in_array(todo, todoInstallCode) &&
	//     (
	//         todo !== 'delivery' || !in_array(deliveryType, ['tk', 'mp_tk'])
	//     )
	// )
	if (canSetTaskInstallers(todo, todoInstallCode, deliveryType)) {

		// const installersMax = selectedInstallersCount;//in_array(todo, todoTwoInstaller) ? 2 : 1;

		const selectInstallers = (e, i) => {
			if (task.installers == undefined)
				task.installers = [];

			const installerId = parseInt(e.target.value);
			if (installerId) {
				task.installers[i] = installerId;
			}
			else
				task.installers = array_unset(task.installers, installerId);

		};

		let ci = installersSelectComponent(
			user.role,
			(in_array(user.role, rolesModerator) && edit),
			selectedInstallersCount,
			installers,
			task.installers ? task.installers : [],
			selectInstallers
		);
		// console.log(edit, ci[0].length);

		let canEditActualDays = edit;
		if (!canEditActualDays && isManagerModerator(user))
			canEditActualDays = true;

		installersSelect.push(
			<div className={"row"} key={"installers-key"}>
				<div className="col-2">
					<FormItem
						edit={edit}
						name={"installers_max"}
						fieldType={"text"}
						numericRound={0}
						label={"Кол-во монтажников (максимальное)"}
						defVal={selectedInstallersCount}
						isRequire={true}
						key={"installers_max"}
						changeAction={e => {
							setSelectedInstallersCount(
								parseInt(e.target.value)
							)
						}}
					/>
				</div>
				<div className="col-2">
					<FormItem
						edit={canEditActualDays}
						name={"actualDays"}
						fieldType={"text"}
						numericRound={0}
						label={"Действительна дней"}
						descr={"начиная от даты исполнения"}
						defVal={task.actualDays ? task.actualDays : 1}
						isRequire={true}
						key={"actualDays"}
						changeAction={e => {
							const days = parseInt(e.target.value);
							if (days > 1) {
								setTodo('instself');
								changeTaskData({
									target: {
										name: 'todo',
										value: 'instself'
									}
								});
							}
						}}
					/>
				</div>
				{/*<div className="col-">*/}
				{/*	{*/}
				{/*		(task.actualDays && task.actualDays > 1)*/}
				{/*		? <FormItem*/}
				{/*				name={"taskEnds"}*/}
				{/*				// label={"asd"}*/}
				{/*				fieldType={*/}
				{/*					<Button*/}
				{/*						type={BUTTON_TYPE.apply}*/}
				{/*						title={"Заявка выполнена досрочно"}*/}
				{/*						ico={"check-circle"}*/}
				{/*					/>*/}
				{/*				}*/}
				{/*			/>*/}
				{/*			: null*/}
				{/*	}*/}
				{/*</div>*/}
			</div>
		);
		// console.log('selectedInstallersCount', selectedInstallersCount);
		if (selectedInstallersCount) {
			let tools = [];
			for (let i in ToolsIcons) {
				tools.push(
					<FormItem
						key={"tool" + i}
						cls={"task-tools-item"}
						edit={edit}
						name={"tools"}
						fieldType={"bool"}
						label={
							<img src={ToolsIcons[i]}/>
						}
						defVal={i}
						isChecked={(deliveryInfo.tools && deliveryInfo.tools.includes(i))}
					/>
				)
			}

			installersSelect.push(
				<div className="flx -alc" key={"tools-box"}>
					<div style={{marginRight: '20px', paddingTop: '20px'}}>
						{tools}
					</div>
					<div>
						<FormItem
							key={"toolsOther"}
							edit={edit}
							name={"toolsOther"}
							// fieldType={"bool"}
							label={"Другое вписать"}
							defVal={deliveryInfo.toolsOther ?? ''}
						/>
					</div>
				</div>
			);
		}
		//монтажники ci - это компонент , массив из 2х массивов
		// console.dir(ci);
		if (ci[0].length) {

			installersSelect.push(
				<FormItem
					edit={edit}
					name={"installers"}
					fieldType={ci[0]}
					label={"Монтажник" + (selectedInstallersCount > 1 ? 'и' : '')}
					key={"installers"}
				/>
			);
		}

		if (in_array(user.role, rolesModerator)) {

			let canEditPrices = true;//install !== null && install.completeDate;

			installersSelect.push(
				<fieldset key={"install-prices"}>
					<legend>Стоимости</legend>
					<div className="row mt20">
						<FormItem
							edit={canEditPrices}
							name={"priceInstall"}
							fieldType={"text"}
							label={"Стоимость монтажа, руб."}
							numericRound={2}
							cls={"col-3"}
							defVal={install ? install.priceInstall : 0}
						/>
						<FormItem
							edit={canEditPrices}
							name={"priceLights"}
							fieldType={"text"}
							label={"Стоимость монтажа подсветки, руб."}
							numericRound={2}
							cls={"col-3"}
							defVal={install ? install.priceLights : 0}
						/>
						<FormItem
							edit={canEditPrices}
							name={"priceUnload"}
							fieldType={"text"}
							label={"Стоимость разгрузки, руб."}
							numericRound={2}
							cls={"col-3"}
							defVal={install ? install.priceUnload : 0}
						/>
					</div>

				</fieldset>
			);
			installersSelect.push(<div className="mt20" key={"install-prices-btm"}></div>);

		}

	}

	//работа с файлом
	// const [fileId, setFileId] = useState((fileData && fileData.fileId) ? parseInt(fileData.fileId) : 0);

	const onDeleteFile = (taskId, fileId) => {
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => deleteFile(taskId, fileId, () => {
					// setFileId(0)
				})
			}
		);
	};

	const fileBox = (
		<div className="form-item">
			<label><i className={"fa fa-files-o"}></i> Файлы</label>
			<ul className="form-files-list">
				{
					filesList.map(file => {
						return (
							<li key={"files-list-" + file.fileId}>
								<a
									href={"#"}
									onClick={e => downloadFile(e, user, file.fileId)}
								>
									{getFileIcon(file.ext)}
									{file.fileName}
								</a>
								{
									edit ? <i
										className="fa fa-close"
										title={"Удалить файл"}
										onClick={e => onDeleteFile(task.id, file.fileId)}
									></i> : null
								}
							</li>
						)
					})
				}
			</ul>
			{
				edit ? <FormItem
					edit={edit}
					name={"attachFile"}
					// label={"Прикрепить файл(ы)"}
					fieldType={"files"}
					isRequire={false}
					reff={attachFileRef}
				/> : null
			}
		</div>
	);

	// if (0 && parseInt(fileData.fileId)) {
	//
	// 	const fileIco = getFileIcon(fileData.ext);
	//
	// 	fileBox = (
	// 		<div className="form-item">
	// 			<label>Файлы</label>
	// 			<ul className="form-files-list">
	// 				<li>
	// 					<a
	// 						href={"#"}
	// 						// href={fileData.fileUrl}
	// 						// target={"_blank"}
	// 						onClick={e => downloadFile(e, user, fileData.fileId)}
	// 					>
	// 						{fileIco}
	// 						{fileData.fileName}
	// 					</a>
	// 					{
	// 						edit ? <i
	// 							className="fa fa-close"
	// 							title={"Удалить файл"}
	// 							onClick={e => onDeleteFile(task.id, fileData.fileId)}
	// 						></i> : null
	// 					}
	//
	// 				</li>
	// 			</ul>
	// 		</div>
	// 	);
	// }
	// else if (edit)
	// 	fileBox = <FormItem
	// 		edit={edit}
	// 		name={"attachFile"}
	// 		label={"Прикрепить файл"}
	// 		fieldType={"files"}
	// 		isRequire={false}
	// 		reff={attachFileRef}
	// 	/>;

	//ДАТЫ
	const oneDayMilliseconds = 24 * 60 * 60 * 1000;
	const getDateFromTime = t => {
		return date("Y-n-j", new Date(t));
	}
	let ddateValue = '';

	const today = Date.now();

	let dateIterator = new Date(GUI.calendarDates.start).getTime();
	if (dateIterator < today)
		dateIterator = today;

	if (dateIterator === today)
		dateIterator += oneDayMilliseconds;

	let exceptedDates = [];
	if (user.settings && user.settings.holidays && user.settings.holidays.length)
		exceptedDates = user.settings.holidays;

	//добавляем все выходные дни в ближайшие 10 дней в даты дней которые надо исключить
	let dateIteratorStart = dateIterator;
	// console.log(dateIteratorStart, date("Y-m-d", new Date(dateIteratorStart)));
	for (let i = 0; i < 20; i++) {
		let dd = new Date(dateIteratorStart);
		let ddDate = getDateFromTime(dateIteratorStart);
		// console.log(dd.getDay(), ddDate);
		// dd.getDay() === 6 || - удалено 25/02/2021
		if (dd.getDay() === 0 && !in_array(ddDate, exceptedDates))
			exceptedDates.push(ddDate);

		dateIteratorStart += oneDayMilliseconds;
	}
	// console.log(exceptedDates);
	let nextDayFormatted = getDateFromTime(dateIterator);
	while (in_array(nextDayFormatted, exceptedDates)) {
		dateIterator += oneDayMilliseconds;
		nextDayFormatted = getDateFromTime(dateIterator);

		// console.log(nextDayFormatted, dateIterator, in_array(nextDayFormatted, exceptedDates));
	}
	// do {
	// 	dateIterator += oneDayMilliseconds;
	// 	nextDayFormatted = getDateFromTime(dateIterator);
	//
	// 	console.log(nextDayFormatted, dateIterator, in_array(nextDayFormatted, exceptedDates));
	// }
	// while (in_array(nextDayFormatted, exceptedDates))

	const nextDay = new Date(dateIterator);
	// console.log(exceptedDates.sort());
	// console.log('ITOG >>>>' + nextDay);

	if (task.ddate != undefined)
		ddateValue = strtotime(task.ddate) * 1000;
	else if (isCopy && copyDate)
		ddateValue = strtotime(copyDate.replaceAll('.', '/')) * 1000;
	else
		ddateValue = nextDay;

	const ddateDate = date("Y-m-d", new Date(ddateValue));

	// useEffect(() => {
	// 		console.log('useEffect');
	// 	},
	//
	// 		[hideFields]
	// );

	// console.log(deliveryType);

	let showMoreCompany = in_array(deliveryType, ['mp_tk']);

	return (
		<form
			encType={"multipart/form-data"}
			ref={formRef}
		>
			<FormItem
				edit={paramTodo !== '' ? false : edit}
				name={"todo"}
				fieldType={"select"}
				options={todoOptions}
				label={"Что делаем"}
				defVal={todo}
				changeAction={changeTaskData}
				isRequire={true}
			/>
			{deliveryFields}
			{installersSelect}


			<div
				className={cn("task-edit-company-list", {hasMore: showMoreCompany})}
				// className={(task.moreCompany) ? "task-edit-company-list" : null}
			>
				<TasksCompanyFields
					edit={edit}
					companySelectContent={companySelectContent}
					hideFields={hideFields}
					requireFields={requireFields}
					company={task.company ? task.company : ''}
					name={task.name ? task.name : ''}
					nakl={(task.nakl && !isCopy) ? task.nakl : ''}
					address={task.address ? task.address : ''}
					comments={task.cmnts ? task.cmnts : ''}
					phoneMobile={task.phoneMobile ? task.phoneMobile : ''}
					phones={task.phones ? task.phones : ''}
					market_num={task.market_num ?? ''}
					commentsPredefined={{
						values: cmntsPredefined,
						add: true,
						separator: "\n"
					}}
					deliveryType={deliveryType}
				/>
				{
					showMoreCompany
						? <TasksCompany
							edit={edit}
							companySelectContent={companySelectContent}
							hideFields={hideFields}
							requireFields={requireFields}
							setTasksData={setTasksData}
							data={task.moreCompany ? task.moreCompany : []}
							commentsPredefined={{
								values: cmntsPredefined,
								add: true,
								separator: "\n"
							}}
							deliveryType={deliveryType}
						/>
						: null

				}
			</div>


			<FormItem
				edit={false}
				name={"manager"}
				label={"Менеджер"}
				defVal={managerName}
				isRequire={in_array("manager", requireFields)}
				hideFields={hideFields}
				ico={"user-secret"}
			/>

			{fileBox}

			<FormItem
				edit={edit}
				fieldType={"date"}
				name={"ddate"}
				label={"Дата исполнения"}
				defVal={ddateDate}
				isRequire={in_array("ddate", requireFields)}
				hideFields={hideFields}
				ico={"calendar-check-o"}
				// blurAction={(e, val) => changeFieldsAction('ddate', 'Дата исполнения', ddateDate, val, true)}
				changeAction={(e, val) => {
					// console.log('ddate change',val);
					changeFieldsAction('ddate', 'Дата исполнения', ddateDate, val, true)
				}}
			/>
			{driverSelect}


			{task.id ? (
				<div className={"_tar"}>
					<div className="edited-info">
						Создано: {date('d.m.Y H:i', new Date(strtotime(task.created) * 1000))}
					</div>
					<div className="edited-info">
						Изменено: {date('d.m.Y H:i', new Date(strtotime(task.edited) * 1000))}
					</div>
					{/*{*/}
					{/*	isAdmin*/}
					{/*		? <div className="edited-info mt20">*/}
					{/*			<NavLink to={`/tasks/changelog/${task.id}`}>История изменений</NavLink>*/}
					{/*		</div>*/}
					{/*		: null*/}
					{/*}*/}

				</div>
			) : null}
		</form>
	)

};

export default TasksEdit;
