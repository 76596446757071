import React from 'react';
import {Win, Button, SmartSelect} from "components/_common";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {in_array} from "utils/func";
import {clearCompanyName} from "../user/ClientsList";

const CompanySelect = props => {

	const {ctype, data, onClose, onSelect} = props;

	let {clients = []} = data;

	if (clients.length == 0)
		return (
			<div className="popup-win">
				<Win
					onClose={onClose}
					header={'Выберите ' + (ctype == 'client' ? 'клиента' : 'поставщика')}
				>
					<p>Клиентов не найдено</p>
					<Button title={"Добавить клиента"} href={"/user/clients/0"} hrefBlank={true}/>
				</Win>
			</div>
		);

	/*
	Новое 04/02/25

	let ssOptions = [];
	clients.map(client => {
		ssOptions.push({
			name: `${client.company}${client.name ? ',' + client.name : ''}${client.phoneMobile ? ',' + client.phoneMobile : ''}`,
			value: `${client.id}`,
			selected: false,
			isGroup: false
		});
	});
	return (
		<div className="popup-win">
			<Win
				onClose={() => onClose(null)}
				header={'Выберать ' + (ctype == 'client' ? 'клиента' : 'поставщика')}
				winClass={"half"}
			>
				<SmartSelect
					placeholder={'Выберите ' + (ctype == 'client' ? 'клиента' : 'поставщика')}
					options={ssOptions}
					multi={false}
					withSearch={ssOptions.length > 20}
					onSelect={(res) => {
						console.dir(res);
						let iter = res.keys();
						let _id = parseInt(iter.next().value);

						if (_id) {
							let selectedClient = null;
							clients.map(client => {
								console.log(client.id, _id, client.id == _id);
								if (client.id == _id) {
									selectedClient = client;
								}
							});
							if (selectedClient)
								onSelect(selectedClient);
						}
					}}
				/>
			</Win>
		</div>
	);
	*/

	let lettersVsCompanyId = {};
	clients.map(item => {
		// console.log(item);
		const companyClear = clearCompanyName(item.company);
		// console.log(companyClear);

		if (companyClear) {
			const fl = companyClear.charAt(0).toLowerCase();

			if (!lettersVsCompanyId.hasOwnProperty(fl))
				lettersVsCompanyId[fl] = [];

			lettersVsCompanyId[fl].push(item.id);
		}
		return null;
	});
	const letters = Object.keys(lettersVsCompanyId).sort();
	console.log(letters);
	console.log(lettersVsCompanyId);

	return (
		<div className="popup-win">
			<Win
				onClose={() => onClose(null)}
				header={'Выберите ' + (ctype == 'client' ? 'клиента' : 'поставщика')}
				winClass={"half"}
			>
				<Tabs>
					<TabList>
						{letters.map(fl => <Tab key={"cs-tab-" + fl}>{fl.toUpperCase()}</Tab>)}
					</TabList>

					{letters.map(l => {
						return (
							<TabPanel key={"cs-tab-panel-" + l}>
								<table className={"tbl dark"}>
									<thead>
									<tr>
										<td>Компания</td>
										<td>Контактное лицо</td>
										<td>Моб. телефон</td>

									</tr>
									</thead>
									<tbody>
									{
										clients.map((item, i) => {
											const ifl = item.company.charAt(0).toLowerCase();
											if (in_array(item.id, lettersVsCompanyId[l]))
												return (
													<tr
														key={"cs-item-" + ifl + i}
														className="task-client-select-item"
														onClick={e => onSelect(item)}
													>
														<td>{item.company}</td>
														<td>{item.name}</td>
														<td>{item.phoneMobile}</td>
													</tr>
												);

										})
									}
									</tbody>
								</table>
							</TabPanel>
						)
					})}

				</Tabs>
				<div className={"mt20"}>
					<Button title={"Добавить клиента"} href={"/user/clients/0"} hrefBlank={true}/>
				</div>

			</Win>
		</div>
	);

};

export default CompanySelect;
