import React, {useState} from 'react';
import BillsMonths from "./BillsMonths";
import {ContentHeader, Win, Button, BUTTON_TYPE, TabsById} from "../_common";
import {isModerator, isUser, unixTime, dateFormat, in_array, showConfirm} from "utils/func";

const EmployeeReportCardTitle = 'Табель сотрудников';

const EmployeeReportCard = props => {
	const {
		user, GUI,
		data,
		onSave,
		onSelectMonth,
		dates, //выбранные даты
		doTablesApiAction,
		tabs, activeTabId, setActiveTabId,
	} = props;

	const [cellTip, setCellTip] = useState('');
	const [deleteRowId, setDeleteRowId] = useState(0);

	const canEdit = isModerator(user) || isUser(user);

	const BillsMonthComponent = <BillsMonths
		GUI={GUI}
		onClick={onSelectMonth}
		dates={dates}
	/>;

	let daysArr = [];
	let curYear = 0;
	let curMonth = 0;
	if (dates.start) {
		curYear = dates.start.getFullYear();
		curMonth = dates.start.getMonth() + 1;
		const curDate = new Date(curYear, curMonth, 0);
		daysArr = new Array(curDate.getDate()).fill(0);
	}
	else
		return <div>Нет дат</div>;

	const saveItem = (id, field, value) => {
		// console.log('save Item');
		// console.log(id, field, value);
		if (field == 'dayHours') {

			let {dayHours = {}} = data[id];
			let {hours = 0} = value;
			if (hours)
				dayHours[value.day] = {
					hours: hours,
					uid: user.id,
					name: user.name,
					time: unixTime()
				};
			else
				delete dayHours[value.day];

			value = dayHours;
		}

		onSave(id, 0, field, value);
	};

	let header = <div className={"flx -alc -sb"}>
		{BillsMonthComponent}
	</div>
	let footer = <div className={"flx -alc -sb"}>
		<div className={'table-report-card-buttons'}>
			<Button
				type={BUTTON_TYPE.save}
				title={"Добавить строку"}
				onClick={() => {

					console.log({year: curYear, month: curMonth});
					// onSave(0, 0, 'newRow', {year: curYear, month: curMonth})
					doTablesApiAction({
						action: 'save',
						id: 0,
						tabId: activeTabId,
						field: 'newRow',
						value: {year: curYear, month: curMonth},
					})
				}}

			/>
			{
				deleteRowId !== 0
					? <Button
						type={BUTTON_TYPE.delete}
						title={"Удалить строку"}
						onClick={() => {
							showConfirm(
								`reportcard-delete-${deleteRowId}`,
								'Подтверджите удаление',
								() => doTablesApiAction({
									action: 'delete',
									id: deleteRowId,
								})
							);

						}}

					/>
					: null
			}
		</div>


		{Object.values(data).length == 0
			? <Button
				type={BUTTON_TYPE.delete}
				ico={"clone"}
				title={"Скопировать таблицу прошлого месяца"}
				onClick={() => {

					doTablesApiAction({
						action: 'copy',
						id: 0,
						tabId: activeTabId,
						field: 'lastMonth',
						value: {year: curYear, month: curMonth},
					}, null, null, true);
				}}
			/>
			: null
		}
	</div>

	const sundays = [], saturdays = [];
	daysArr.map((v, i) => {
		const thisDay = new Date(curYear, curMonth - 1, i + 1);
		// console.log(thisDay);
		if (thisDay.getDay() == 0)
			sundays.push(i + 1);
		if (thisDay.getDay() == 6)
			saturdays.push(i + 1);
	});

	const dayClassName = (day) => {
		return in_array(day, sundays)
			? "su"
			: (
				in_array(day, saturdays)
					? "st"
					: null
			)
	}
	const tabsData = [];
	tabs.map((tab, i) => {
		// console.log(tab);

		tabsData.push({
			id: tab.id,
			title: tab.name,
			content: (
				<div>
					{header}
					<br/>
					<table className={"tbl dark -bordered table-report-card"}>
						<thead>
						<tr>
							<td className={"fio"}>
								Ф.И.О
							</td>
							<td className={'job'}>
								Специаль&shy;ность
							</td>
							<td className={'conts'}>
								Контакты
							</td>
							{
								daysArr.map((v, i) => {

									let day = i + 1;
									return <td
										key={`erc-thead-${i}`}
										className={dayClassName(day)}
									>
										{day}
									</td>
								})
							}
							<td>
								Итог
							</td>
						</tr>
						</thead>
						<tbody className={"tbody"}>
						{Object.values(data).map((row, i) => {
							let sum = 0;
							let {dayHours = {}} = row;
							return (
								<tr key={`erc-tr-${i}`}>
									<td
										contentEditable={canEdit}
										suppressContentEditableWarning={true}
										onFocus={e => {
											setDeleteRowId(row.id);

										}}
										onBlur={e => {

											if (row.fio != e.currentTarget.innerText)
												saveItem(row.id, 'fio', e.currentTarget.innerText);

											setTimeout(() => {
												setDeleteRowId(0);
											}, 250);
										}}

									>
										{row.fio}
									</td>
									<td
										contentEditable={canEdit}
										suppressContentEditableWarning={true}

										onBlur={e => {
											if (row.job != e.currentTarget.innerText)
												saveItem(row.id, 'job', e.currentTarget.innerText);

										}}

									>
										{row.job}
									</td>
									<td
										contentEditable={canEdit}
										suppressContentEditableWarning={true}

										onBlur={e => {
											if (row.contacts != e.currentTarget.innerText)
												saveItem(row.id, 'contacts', e.currentTarget.innerText);

										}}

									>
										{row.contacts}
									</td>
									{
										daysArr.map((v, i) => {

											let day = i + 1;

											let title = null;
											let hours = 0;

											if (dayHours[day]) {
												// console.log(day, typeof dayHours[day]);
												if (typeof dayHours[day] == 'object') {
													let {hours: _h = '', uid = 0, name = '', time = 0} = dayHours[day];
													hours = _h ? parseFloat(_h) : 0;
													// console.log(day, hours);
													if (name && time) {
														title = `Установил ${name}, ${dateFormat(time, 'd.m.Y H:i')}`;
													}

												}
												else
													hours = dayHours[day];

											}

											hours = parseFloat(hours);

											sum += hours;

											return <td
												key={`erc-day-${row.id}-${i}`}
												contentEditable={canEdit}
												suppressContentEditableWarning={true}
												onBlur={e => {
													setCellTip('');

													let strValue = e.currentTarget.innerText;
													let floatValue = strValue ? parseFloat(strValue) : 0;
													// console.log(day, hours, floatValue);
													if (hours !== floatValue)
														saveItem(row.id, `dayHours`, {
															day: day,
															hours: floatValue
														});
												}}
												onFocus={() => {
													setCellTip(title || '');
												}}
												title={title}
												className={dayClassName(day)}
											>
												{hours || ''}
											</td>
										})
									}
									<td className={"it"}>
										{sum}
									</td>
								</tr>
							);
						})}
						</tbody>
					</table>

					<div className={"table-report-card-tip"}>
						{cellTip}
					</div>

					{footer}
				</div>
			),
		});
	});

	return (
		<>
			<ContentHeader
				// title={EmployeeReportCardTitle}
				title={""}
				showDates={false}
			/><TabsById
			tabs={tabsData}
			tabPrefix={'reportcard-tabs'}
			url={'/tables/reportcard'}

			onChange={tabId => {

				setActiveTabId(tabId);
			}}
		/>

			{/*<Win*/}
			{/*	header={header}*/}
			{/*	footer={footer}*/}
			{/*>*/}
			{/*	*/}


			{/*</Win>*/}
		</>
	)

};

export default EmployeeReportCard;