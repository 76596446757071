import React, {useRef} from 'react';
// import {isModerator} from "utils/func";
// import {rolesModerator} from "utils/config";
import {FormItem, BUTTON_TYPE, Button} from "components/_common";

const TasksListTableRowPrices = props => {
	const {
		taskId,
		canEdit = true,
		install = {},
		onSave
	} = props;
	const {priceInstall = 0, priceLights = 0, priceUnload = 0} = install;

	const priceInstallRef = useRef(0);
	const priceLightsRef = useRef(0);
	const priceUnloadRef = useRef(0);

	return (
		<div className={"tasks-prices"}>
			<h3>Стоимость, руб.:</h3>
			<FormItem
				edit={canEdit}
				name={"priceInstall"}
				fieldType={"text"}
				label={"монтажа"}
				numericRound={0}
				defVal={priceInstall}
				reff={priceInstallRef}
			/>
			<FormItem
				edit={canEdit}
				name={"priceLights"}
				fieldType={"text"}
				label={"монтажа подсветки"}
				numericRound={0}
				defVal={priceLights}
				reff={priceLightsRef}
			/>
			<FormItem
				edit={canEdit}
				name={"priceUnload"}
				fieldType={"text"}
				label={"разгрузки"}
				numericRound={0}
				defVal={priceUnload}
				reff={priceUnloadRef}
			/>
			<Button
				ico={"save"}
				type={BUTTON_TYPE.apply}
				onClick={e => {
					onSave(
						null,
						'',
						'setInstallFields',
						{
							id: taskId,
							data: {
								priceInstall: priceInstallRef.current.value || 0,
								priceLights: priceLightsRef.current.value || 0,
								priceUnload: priceUnloadRef.current.value || 0
							}
						}
					);
				}}
			/>
		</div>
	)

};

export default TasksListTableRowPrices;